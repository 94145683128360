import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery,Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"


import HeaderMinimal from "../../../components/header/HeaderMinimal"
import MainMenu from "../../../components/header/Menu"
import Footer from "../../../components/footer/Footer"

import Sidebar from "../../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../../components/major-pieces/pageblock/PageBlock"
import CovidPlan from "../../../../static/pdf/school/School-Covid-Plan-Update-11-9-20.pdf"

import "../../../styles/global.css"
import "../../../styles/global_media.css"
import "../../../styles/menu.css"
import "../../../styles/school.css"
import "../../../styles/header.css"


function ERI () {
	
	

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content school-colors school">
				<div>
					<div className="subheader">
					   <h1 className="name"> 
					   		Saint John's Orthodox Christian School
				       </h1>
					</div>
					
				</div>

				<div  className = "fellowship">
					<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfb_J9lo0u_cDJUjszktnzIOybtz0LFLtjnHhrghJAd6a30Kw/viewform?embedded=true" width="100%" height="1000" overflow="hidden" frameBorder="0" marginHeight="0" marginWidth="0" >Loading…</iframe>
				</div>

				
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 